<template>
  <div class="space-management">
    <h1>Space Management</h1>
    <div v-if="spaces.length" class="space-list">
      <div
        v-for="space in spaces"
        :key="space._id"
        class="space-item"
        @click="viewDetails(space)"
      >
        <img
          :src="getSpaceImage(space)"
          alt="Space Image"
          class="space-image"
        />
        <div class="space-details">
          <h2>{{ space.typeData.type }} space</h2>
          <p>{{ space.locationData.city }}, {{ space.locationData.country }}</p>
          <p>{{ space.dimensionsData.surface }}m²</p>
        </div>
      </div>
    </div>
    <div v-else class="no-spaces">
      No spaces registered for this corporate account.
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      spaces: [],
    };
  },
  computed: {
    ...mapGetters(["corporateId"]),
  },
  methods: {
    ...mapActions(["fetchCorporateDetails"]),
    async fetchSpaces() {
      if (!this.corporateId) {
        await this.fetchCorporateDetails();
      }

      if (this.corporateId) {
        try {
          const response = await axios.get(
            `/api/spaces/corporate/${this.corporateId}`
          );
          this.spaces = response.data;
        } catch (error) {
          console.error("Error loading spaces:", error);
        }
      }
    },
    getSpaceImage(space) {
      if (space.multimediaData.images.uploaded.length > 0) {
        return space.multimediaData.images.uploaded[0]; // Use the first uploaded image
      } else {
        return require("@/assets/images/free-advertisement.png");
      }
    },
    viewDetails(space) {
      // Logic to navigate to the detailed view
      this.$router.push({
        name: "SpaceDetails",
        params: { spaceId: space._id },
      });
    },
  },
  mounted() {
    this.fetchSpaces();
  },
};
</script>

<style scoped>
.space-management {
  font-weight: 500;
  color: #004aad;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  text-align: center;
  color: #004aad;
  margin-bottom: 20px;
  font-size: 32px;
  border-bottom: 2px solid #004aad;
  padding-bottom: 10px;
}

.space-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.space-item {
  display: flex;
  background-color: #f5f5f5;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 600px;
  cursor: pointer;
}

.space-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.space-image {
  width: 33.3%;
  height: 100%;
  object-fit: cover;
}

.space-details {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 66.7%;
}

.space-details h2 {
  color: #004aad;
  margin-bottom: 10px;
  font-size: 24px;
}

.space-details p {
  color: #333;
  margin: 0;
  font-size: 18px;
}

.no-spaces {
  text-align: center;
  color: #333;
  font-size: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}
</style>
