<template>
  <div class="content-section account-details">
    <h1 class="section-title">
      <i class="fa-solid fa-gears"></i>
      Services Management
    </h1>
    <div v-if="services.length === 0">
      <p>No services found.</p>
    </div>
    <div v-else>
      <div v-for="service in services" :key="service.id" class="space-item">
        <h2>{{ service.name }}</h2>
        <div class="image-section">
          <img :src="service.imageUrl" alt="Service image" />
        </div>
        <div class="info-dimensions">
          <span>{{ service.description }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      services: [],
    };
  },
  methods: {
    async fetchUserServices() {
      try {
        const response = await axios.get("/api/services");
        if (response.status === 200) {
          this.services = response.data;
        } else {
          console.error("Failed to fetch services.");
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    },
  },
  mounted() {
    this.fetchUserServices();
  },
};
</script>

<style scoped>
.content-section {
  padding: 0 0 0 40px;
  max-width: max-content;
}
.section-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 10px 0;
  color: #004aad;
}
.space-item {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.image-section img {
  max-width: 100%;
}
.info-dimensions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
</style>
