<template>
  <div id="space-results">
    <ResultsList :resultsItems="results" />
  </div>
</template>

<script>
import axios from "axios";
import ResultsList from "@/components/Search/ResultsList.vue";

export default {
  name: "AdvertisementsResults",
  components: {
    ResultsList,
  },
  data() {
    return {
      results: [],
    };
  },
  async created() {
    const space = await axios.get("/api/results");
    const spaces = space.data;
    this.results = spaces; // Assign fetched data to this.results
  },
};
</script>
