<template>
  <div class="file-upload-container">
    <label :for="inputId" class="file-upload-label">
      {{ label }}
    </label>
    <input
      :id="inputId"
      type="file"
      @change="handleFileChange"
      :multiple="multiple"
      :accept="acceptedFormats"
    />
    <div v-if="filePreviews.length" class="file-preview-list">
      <div
        v-for="(file, index) in filePreviews"
        :key="index"
        class="file-preview"
      >
        <span>{{ file.name }} ({{ (file.size / 1024).toFixed(2) }} KB)</span>
        <button type="button" @click="removeFile(index)">Remove</button>
      </div>
    </div>
    <button @click="uploadFiles" :disabled="!files.length">Upload</button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "FileUpload",
  props: {
    uploadUrl: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "Upload File(s)",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    acceptedFormats: {
      type: String,
      default: "*/*", // Default accepts all formats
    },
  },
  data() {
    return {
      files: [],
      filePreviews: [],
    };
  },
  computed: {
    inputId() {
      return `file-upload-${this._uid}`;
    },
  },
  methods: {
    // Handle file input change
    handleFileChange(event) {
      this.files = Array.from(event.target.files);
      this.generatePreviews();
      this.$emit("file-selected", this.files); // Emit selected files
    },
    // Remove a selected file
    removeFile(index) {
      this.files.splice(index, 1);
      this.filePreviews.splice(index, 1);
    },
    // Generate previews for files (or show names for non-image files)
    generatePreviews() {
      this.filePreviews = this.files.map((file) => ({
        name: file.name,
        size: file.size,
      }));
    },
    // Upload files to the server
    async uploadFiles() {
      const formData = new FormData();
      this.files.forEach((file) => formData.append("files", file));

      try {
        const response = await axios.post(this.uploadUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.$emit("file-uploaded", response.data);
        this.reset();
      } catch (error) {
        console.error("File upload error:", error);
      }
    },
    // Reset file input and preview
    reset() {
      this.files = [];
      this.filePreviews = [];
      this.$refs.fileInput.value = "";
    },
  },
};
</script>

<style scoped>
.file-upload-container {
  margin-top: 10px;
}

.file-preview-list {
  margin-top: 10px;
}

.file-preview {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

button {
  margin-top: 10px;
}
</style>
