<template>
  <div class="form-container">
    <label for="login-form" class="title">
      <i class="fa-solid fa-sign-in-alt fa-lg"></i>
      User Login
    </label>
    <form id="login-form" @submit.prevent="handleSubmit">
      <label for="email">E-mail</label>
      <input
        type="email"
        id="email"
        v-model="form.email"
        required
      /><br /><br />

      <div class="password-div">
        <label for="password">Password</label>
        <div class="input-and-button">
          <input
            :type="passwordFieldType"
            autocomplete="off"
            class="password"
            name="password"
            id="password"
            v-model="form.password"
          />
          <button
            type="button"
            class="toggle-password"
            @click="togglePassword()"
          >
            <i :class="passwordIcon"></i>
          </button>
        </div>
      </div>
      <br /><br />

      <button type="submit" class="submit-button">Login</button>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "UserLogin",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      passwordFieldType: "password",
      passwordIcon: "fa-solid fa-eye-slash",
      errorMessage: null,
    };
  },
  methods: {
    ...mapActions(["login"]),
    async handleSubmit() {
      try {
        this.errorMessage = null;
        await this.login({
          email: this.form.email,
          password: this.form.password,
        });
        this.$router.push({ name: "your-account" });
      } catch (error) {
        this.errorMessage = error.message || "An error occurred during login.";
      }
    },
    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordIcon =
        this.passwordFieldType === "password"
          ? "fa-solid fa-eye-slash"
          : "fa-solid fa-eye";
    },
  },
};
</script>

<style scoped>
.form-container {
  background-color: white;
  padding: 20px;
  margin: 10px auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
}

.title {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 10px auto 26px auto;
  text-align: center;
  color: #004aad;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #004aad;
}

input[type="email"],
input[type="password"] {
  border-radius: 8px;
  width: 100% !important;
  margin: auto;
  padding: 0.67rem 1rem;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

input[type="email"]:focus,
input[type="password"]:focus {
  border-color: #004aad;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 74, 173, 0.5);
}

.password-div {
  display: flex;
  flex-direction: column;
}

.input-and-button {
  display: flex;
  flex-direction: row;
}

.toggle-password {
  min-width: 50px;
  padding: 0.67rem;
  background-color: #004aad;
  border-radius: 0 8px 8px 0;
  color: #ffde59;
  border: 1px solid #004aad;
  border-left: none;
  flex: 1;
  margin: 0;
}

.toggle-password:hover {
  background-color: #ffde59;
  color: #004aad;
}

.password {
  border-radius: 8px 0 0 8px !important;
  width: calc(100% - 50px);
  margin: auto;
  padding: 0.67rem 1rem;
  border: 1px solid #ccc;
}

.password:focus {
  border-color: #004aad;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 74, 173, 0.5);
}

.submit-button {
  display: block;
  margin: 0 auto;
  padding: 10px 16px;
  background-color: #004aad;
  color: #ffde59;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
}

.submit-button:hover {
  background-color: #ffde59;
  color: #004aad;
}
</style>
