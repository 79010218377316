<template>
  <aside class="flex flex-col w-full p-2 shadow-md rounded-lg">
    <div class="flex flex-row justify-between my-2">
      <button
        class="text-yellow w-fit mx-auto rounded p-2 bg-darkblue font-medium hover:bg-yellow hover:text-darkblue"
      >
        <i class="fa-solid fa-bell mx-[.33rem]"></i>
        Save search
      </button>
      <!-- <div class="w-5/6 mx-auto h-px border border-darkblue rounded-lg my-3"></div> -->
      <button
        class="text-yellow w-fit mx-auto rounded p-2 bg-darkblue font-medium hover:bg-yellow hover:text-darkblue"
      >
        <i class="fa-solid fa-filter mx-[.33rem]"></i>
        Filter
      </button>
    </div>
    <div
      class="w-5/6 mx-auto h-px border border-darkblue rounded-lg my-3"
    ></div>
    <label for="location" class="text-darkblue font-medium mt-1"
      >Location</label
    >
    <div class="relative mt-[.25rem] mb-4">
      <input
        type="text"
        id="location"
        placeholder="City, area or country"
        class="text-darkblue border border-darkblue rounded-md p-2 pl-8 w-full"
      />
      <i
        class="fa-solid fa-location-dot absolute top-1 left-3 py-0aureo text-darkblue"
      ></i>
    </div>
    <label for="max-price" class="text-darkblue font-medium">Price</label>
    <div class="relative mt-[.25rem] mb-4 flex flex-row jsutify-between">
      <input
        type="number"
        id="max-price"
        placeholder="Max Price"
        class="text-darkblue border border-darkblue rounded-l-md p-2 pl-9 appearance-none w-[14ch]"
      />
      <i
        class="fa-solid fa-coins absolute top-1 left-3 py-0aureo text-darkblue"
      ></i>
      <select
        name="currency"
        id="currency"
        class="text-darkblue border border-darkblue rounded-r-md py-2 px-1 min-w-12"
      >
        <option value="euro">€</option>
        <option value="dollar">$</option>
        <option value="pound">£</option>
        <option value="bitcoin">₿</option>
      </select>
      <span class="mx-2 text-xl pt-2 text-darkblue">/</span>
      <select
        name="per-area"
        id="per-area"
        class="text-darkblue border border-darkblue rounded-md py-2 px-1 min-w-fit"
      >
        <option value="total">total</option>
        <option value="m2">m²</option>
        <option value="ft2">ft²</option>
        <option value="eu-pallet">EU pallet</option>
        <option value="us-pallet">US pallet</option>
      </select>
    </div>
    <label for="space" class="text-darkblue font-medium">Space</label>
    <div class="relative mt-[.25rem] mb-4 flex flex-row">
      <input
        type="number"
        id="space"
        placeholder="Min Space"
        class="text-darkblue border border-darkblue rounded-l-md p-2 pl-9 appearance-none"
      />
      <i
        class="fa-solid fa-maximize absolute top-1 left-3 py-0aureo text-darkblue"
      ></i>
      <select
        name="area-unit"
        id="area-unit"
        class="text-darkblue border border-darkblue rounded-r-md py-2 px-1 min-w-12"
      >
        <option value="m2">m²</option>
        <option value="ft2">ft²</option>
      </select>
    </div>
    <div class="relative mt-[.25rem] mb-4">
      <label for="date-range" class="text-darkblue font-medium"
        >Date Range</label
      >
      <ejs-daterangepicker
        placeholder="Select a date range"
        :minDays="28"
        format="dd/MMM/yyyy"
        class=""
        id="date-range"
      ></ejs-daterangepicker>
    </div>
  </aside>
</template>

<script>
import { DateRangePickerComponent } from "@syncfusion/ej2-vue-calendars";

export default {
  name: "SearchFilters",
  components: {
    "ejs-daterangepicker": DateRangePickerComponent,
  },
};
</script>

<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="number"] {
  -webkit-appearance: none;
  margin: 0;
}
</style>
