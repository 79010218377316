<template>
  <Header />
  <div class="register-view">
    <UserRegister
      v-if="step === 2"
      @corporate-registered="handleCorporateRegistered"
    />
    <CorporateRegister
      v-if="step === 1"
      :isAdmin="true"
      :corporateId="corporateId"
    />
  </div>
  <Footer />
</template>

<script>
import Header from "@/components/common/Header.vue";
import CorporateRegister from "@/components/SignIn/CorporateRegister.vue";
import UserRegister from "@/components/SignIn/UserRegister.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  metaInfo: {
    title: "Corporate & Admin Register | Biglo",
  },
  name: "CorporateAdminRegister",
  components: {
    Header,
    CorporateRegister,
    UserRegister,
    Footer,
  },
  data() {
    return {
      step: 1,
      corporateId: null,
    };
  },
  methods: {
    handleCorporateRegistered(corporateData) {
      console.log("Corporate registered:", corporateData);
      this.corporateId = corporateData.id; // Set the corporateId from the event data
      this.step = 2; // Move to the next step
    },
  },
};
</script>

<style scoped></style>
