<template>
  <div>
    <div class="webflow-content">
      <div v-html="webflowHtml"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WebflowContent",
  data() {
    return {
      webflowHtml: "", // This will hold the Webflow HTML content
    };
  },
  mounted() {
    // Fetch the HTML content from Webflow's index.html
    fetch("/webflow/index.html")
      .then((response) => response.text())
      .then((html) => {
        this.webflowHtml = html;

        // Reinitialize Webflow animations and transitions after loading HTML
        this.loadWebflowJS();
        this.initWebflow();
      });
  },
  methods: {
    loadWebflowJS() {
      const script = document.createElement("script");
      script.src = "/webflow/js/webflow.js";
      script.async = true;
      document.body.appendChild(script);
    },
    initWebflow() {
      // Reinitialize Webflow once the content and JS are loaded
      setTimeout(() => {
        window.Webflow && window.Webflow.destroy(); // Destroy previous Webflow instance if any
        window.Webflow && window.Webflow.ready(); // Initialize Webflow animations
      }, 500); // Timeout to ensure scripts are fully loaded
    },
  },
};
</script>

<!-- Remove scoped attribute so Webflow styles apply globally -->
<style>
@import "@/../public/webflow/css/normalize.css";
@import "@/../public/webflow/css/webflow.css";
@import "@/../public/webflow/css/biglos-landing-page-74f47e.webflow.css";

/* You can add any additional styles here */
</style>
