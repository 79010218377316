<template>
  <div class="space-details">
    <div class="space-details-header">
      <img :src="spaceImage" alt="Space Image" class="space-image" />
      <div class="space-basic-info">
        <h1>
          {{ space.typeData.type }} - {{ space.locationData.city }},
          {{ space.locationData.country }}
        </h1>
        <h2>{{ space.dimensionsData.surface }} m²</h2>
      </div>
    </div>

    <div class="space-details-body">
      <h3>Location</h3>
      <p>
        {{ space.locationData.address }}, {{ space.locationData.city }},
        {{ space.locationData.region }}, {{ space.locationData.zip }}
      </p>

      <h3>Dimensions</h3>
      <p>Surface: {{ space.dimensionsData.surface }} m²</p>
      <p>Height: {{ space.dimensionsData.height }} m</p>
      <p>Pallet Positions: {{ space.dimensionsData.palletPositions }}</p>

      <h3>Operations</h3>
      <ul>
        <li v-for="(value, key) in space.operationsData" :key="key">
          {{ key }}: {{ value }}
        </li>
      </ul>

      <h3>Storage</h3>
      <div v-if="space.storageData">
        <h4>Block Storage</h4>
        <ul>
          <li v-for="(value, key) in space.storageData.blockStorage" :key="key">
            {{ key }}: {{ value }}
          </li>
        </ul>
        <h4>Direct Storage</h4>
        <ul>
          <li
            v-for="(value, key) in space.storageData.directStorage"
            :key="key"
          >
            {{ key }}: {{ value }}
          </li>
        </ul>
        <h4>Automated Storage</h4>
        <ul>
          <li
            v-for="(value, key) in space.storageData.automatedStorage"
            :key="key"
          >
            {{ key }}: {{ value }}
          </li>
        </ul>
      </div>

      <h3>Equipment</h3>
      <ul>
        <li v-for="(value, key) in space.equipmentData" :key="key">
          {{ key }}: {{ value }}
        </li>
      </ul>

      <h3>Goods</h3>
      <ul>
        <li v-for="(value, key) in space.goodsData" :key="key">
          {{ key }}: {{ value }}
        </li>
      </ul>

      <h3>Licenses</h3>
      <ul>
        <li v-for="(value, key) in space.licensesData" :key="key">
          {{ key }}: {{ value }}
        </li>
      </ul>

      <h3>Description</h3>
      <p>{{ space.descriptionData.descriptionText }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SpaceDetails",
  data() {
    return {
      space: null,
      spaceId: this.$route.params.spaceId,
    };
  },
  computed: {
    spaceImage() {
      if (this.space && this.space.multimediaData.images.uploaded.length > 0) {
        return this.space.multimediaData.images.uploaded[0];
      } else {
        return require("@/assets/images/free-advertisement.png");
      }
    },
  },
  created() {
    this.fetchSpaceDetails();
  },
  methods: {
    async fetchSpaceDetails() {
      try {
        const response = await axios.get(`/api/spaces/${this.spaceId}`);
        this.space = response.data;
      } catch (error) {
        console.error("Error fetching space details:", error);
      }
    },
  },
};
</script>

<style scoped>
.space-details {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.space-details-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #004aad;
  padding-bottom: 10px;
}

.space-image {
  width: 33%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
}

.space-basic-info {
  flex-grow: 1;
}

.space-basic-info h1 {
  color: #004aad;
  margin: 0;
}

.space-basic-info h2 {
  color: #ffde59;
  margin: 5px 0 0 0;
}

.space-details-body {
  margin-top: 20px;
}

.space-details-body h3 {
  color: #004aad;
  margin-top: 20px;
}

.space-details-body ul {
  list-style-type: none;
  padding-left: 0;
}

.space-details-body ul li {
  background: #f1f1f1;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 4px;
}
</style>
