<template>
  <div class="content-section account-details">
    <h1 class="section-title">
      <i class="fa-solid fa-user fa-lg"></i>
      User Details
    </h1>
    <form @submit.prevent="updateUser">
      <div class="input-container">
        <label for="user-name">Name</label>
        <input
          type="text"
          name="user-name"
          v-model="formData.userName"
          id="user-name"
          placeholder="Name"
        />
      </div>
      <div class="input-container">
        <label for="user-email">Email</label>
        <input
          type="email"
          name="user-email"
          id="user-email"
          v-model="formData.email"
        />
      </div>
      <span v-if="!isEmailValid()" class="error-message">
        {{ emailErrorMessage }}
      </span>

      <!-- Telephone Input -->
      <div class="input-container">
        <label for="user-telephone">Telephone</label>
        <input
          type="tel"
          ref="telephone"
          name="user-telephone"
          id="user-telephone"
          placeholder="Telephone"
          v-model="formData.telephone"
          @input="restrictToDigits"
        />
      </div>
      <span v-if="!isPhoneValid() && formData.telephone" class="error-message">
        {{ telephoneErrorMessage }}
      </span>

      <!-- New Password Input -->
      <div class="password-div">
        <label for="user-password">New Password</label>
        <div class="input-and-button">
          <input
            :type="newPasswordFieldType"
            autocomplete="off"
            class="user-password"
            name="user-password"
            id="user-password"
            v-model="formData.newPassword"
          />
          <button
            type="button"
            class="toggle-password"
            @click="togglePassword('new')"
          >
            <i :class="newPasswordIcon"></i>
          </button>
        </div>
      </div>
      <span
        v-if="!isPasswordComplex() && formData.newPassword.length > 0"
        class="error-message"
      >
        {{ passwordErrorMessage }}
      </span>

      <div v-if="!isFormInvalid()" class="password-div current-password-div">
        <label for="current-password">Current Password</label>
        <div class="input-and-button">
          <input
            :type="currentPasswordFieldType"
            class="user-password"
            name="current-password"
            id="current-password"
            v-model="formData.currentPassword"
            required
          />
          <button
            type="button"
            class="toggle-password"
            @click="togglePassword('current')"
          >
            <i :class="currentPasswordIcon"></i>
          </button>
        </div>
      </div>
      <button
        type="submit"
        class="submit-button"
        :disabled="isFormInvalid() || !formData.currentPassword"
        :data-text="dataText"
      >
        Save Changes
      </button>
    </form>
    <!-- Notification Component -->
    <NotificationCustom
      v-for="(notification, index) in notifications"
      :key="index"
      :type="notification.type"
      :message="notification.message"
      :duration="notification.duration"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import NotificationCustom from "@/components/common/NotificationCustom.vue";

export default {
  name: "UserDetails",
  components: { NotificationCustom },
  data() {
    return {
      originalData: {
        userName: "",
        email: "",
        telephoneCountryPrefix: "",
        telephone: "",
      },
      formData: {
        userName: "",
        email: "",
        telephoneCountryPrefix: "",
        telephone: "",
        newPassword: "",
        currentPassword: "",
      },
      newPasswordFieldType: "password",
      currentPasswordFieldType: "password",
      newPasswordIcon: "fa-solid fa-eye-slash",
      currentPasswordIcon: "fa-solid fa-eye-slash",
      emailErrorMessage: "",
      telephoneErrorMessage: "",
      passwordErrorMessage: "",
      notifications: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user, // Assuming 'user' is the state property
    }),
    dataText() {
      if (this.noChangesMade()) {
        return "You haven't made any change";
      } else if (this.isFieldEmpty()) {
        return "You cannot leave formerly filled fields empty";
      } else {
        return "Check every field";
      }
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(newUser) {
        console.log("Watcher triggered with user data:", newUser); // Debugging line
        if (newUser) {
          this.originalData = {
            userName: newUser.name,
            email: newUser.email,
            telephoneCountryPrefix: newUser.telephoneCountryPrefix || "",
            telephone: newUser.telephone,
          };

          // Update formData with user details
          this.formData.userName = newUser.name;
          this.formData.email = newUser.email;
          this.formData.telephoneCountryPrefix =
            newUser.telephoneCountryPrefix || "";
          this.formData.telephone = newUser.telephone;
        }
      },
    },
  },

  methods: {
    ...mapActions(["fetchUser", "updateUserAction"]),
    async updateUser() {
      if (this.isFormInvalid()) return;
      try {
        const updatedUser = {
          ...this.user,
          name: this.formData.userName,
          email: this.formData.email,
          telephoneCountryPrefix: this.formData.telephoneCountryPrefix,
          telephone: this.formData.telephone,
          password: this.formData.newPassword,
        };
        await this.updateUserAction({
          user: updatedUser,
          currentPassword: this.formData.currentPassword,
        });
        // Clear fields after successful update
        this.formData.newPassword = "";
        this.formData.currentPassword = "";
        // Show success notification and emit event
        this.addNotification("User details updated successfully!", "success");
      } catch (error) {
        console.error("Error updating user:", error);
        this.addNotification("Error submitting form.", "error");
      }
    },
    togglePassword(version) {
      if (version === "new") {
        this.newPasswordFieldType =
          this.newPasswordFieldType === "password" ? "text" : "password";
        this.newPasswordIcon =
          this.newPasswordFieldType === "password"
            ? "fa-solid fa-eye-slash"
            : "fa-solid fa-eye";
      } else if (version === "current") {
        this.currentPasswordFieldType =
          this.currentPasswordFieldType === "password" ? "text" : "password";
        this.currentPasswordIcon =
          this.currentPasswordFieldType === "password"
            ? "fa-solid fa-eye-slash"
            : "fa-solid fa-eye";
      }
    },
    isEmailValid() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (this.formData.email.includes("+")) {
        this.emailErrorMessage = 'This field cannot contain "+"';
        return false;
      } else if (!emailRegex.test(this.formData.email)) {
        this.emailErrorMessage = "Invalid email format";
        return false;
      } else {
        this.emailErrorMessage = ""; // Clear error message if valid
        return true;
      }
    },
    isPasswordComplex() {
      if (!this.formData.newPassword) {
        return true;
      }
      const symbolRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      const uppercaseRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const numberRegex = /[0-9]/;
      const password = this.formData.newPassword;
      if (password.length < 10) {
        this.passwordErrorMessage =
          "Password must contain at least 10 characters";
        return false;
      } else if (!symbolRegex.test(password)) {
        this.passwordErrorMessage =
          "Password must contain at least one special symbol";
        return false;
      } else if (!uppercaseRegex.test(password)) {
        this.passwordErrorMessage =
          "Password must contain at least one uppercase letter";
        return false;
      } else if (!lowercaseRegex.test(password)) {
        this.passwordErrorMessage =
          "Password must contain at least one lowercase letter";
        return false;
      } else if (!numberRegex.test(password)) {
        this.passwordErrorMessage = "Password must contain at least one number";
        return false;
      } else {
        this.passwordErrorMessage = ""; // Clear error message if valid
        return true;
      }
    },
    loadIntlTelInputUtils() {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src =
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js";
        script.onload = resolve;
        document.head.appendChild(script);
      });
    },
    restrictToDigits(event) {
      const input = event.target.value;
      this.formData.telephone = input.replace(/\D/g, ""); // Restrict to digits
    },
    isPhoneValid() {
      const phoneRegex = /^[0-9]{9,15}$/;
      if (!phoneRegex.test(this.formData.telephone)) {
        this.telephoneErrorMessage =
          "Phone number must be between 9 and 15 digits.";
        return false;
      } else {
        this.telephoneErrorMessage = ""; // Clear error message if valid
        return true;
      }
    },
    getCountryCodeFromDialCode(dialCode) {
      const countries = intlTelInput.getCountryData();
      for (const country of countries) {
        if (country.dialCode === dialCode) {
          return country.iso2;
        }
      }
      return "auto";
    },
    isFormInvalid() {
      return (
        this.noChangesMade() ||
        this.isFieldEmpty() ||
        !this.isEmailValid() ||
        !this.isPhoneValid() ||
        !this.isPasswordComplex()
      );
    },
    noChangesMade() {
      return (
        this.formData.userName === this.originalData.userName &&
        this.formData.email === this.originalData.email &&
        this.formData.telephoneCountryPrefix ===
          this.originalData.telephoneCountryPrefix &&
        this.formData.telephone === this.originalData.telephone &&
        this.formData.newPassword.length === 0
      );
    },
    isFieldEmpty() {
      return (
        (this.originalData.userName && !this.formData.userName) ||
        (this.originalData.email && !this.formData.email) ||
        (this.originalData.telephone && !this.formData.telephone)
      );
    },
    initializePhoneInput() {
      const input = this.$refs.telephone;
      this.iti = intlTelInput(input, {
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        initialCountry: this.getCountryCodeFromDialCode(
          this.formData.telephoneCountryPrefix
        ), // Set based on stored data
        autoPlaceholder: "aggressive",
      });
      if (this.formData.telephone) {
        this.iti.setNumber(
          `+${this.formData.telephoneCountryPrefix}${this.formData.telephone}`
        );
      }
    },
    /* Add a notification */
    addNotification(message, type, duration = 10000) {
      this.notifications.push({ message, type, duration });
      setTimeout(() => {
        this.notifications.shift(); // Remove the first notification after its duration
      }, duration);
    },
  },
  mounted() {
    if (this.user) {
      console.log("User found in Vuex:", this.user); // Debugging line
      this.fetchUser(this.user._id); // Fetch user details using Vuex action
    } else {
      console.log("No user found in Vuex."); // Debugging line
    }
    this.initializePhoneInput();
  },
};
</script>

<style scoped>
.content-section {
  padding: 0 0 0 40px;
  max-width: max-content;
}
.section-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 10px 0;
  color: #004aad;
}
.input-container {
  margin: 10px 0;
}
.input-container > label {
  display: block;
}
input[type="text"],
input[type="tel"],
input[type="email"] {
  border-radius: 8px;
  width: 400px;
  margin: auto;
  padding: 0.67rem 1rem;
  border: 1px solid #004aad;
}
.error-message {
  display: block;
  color: #ff0000;
  margin: 5px 0 10px 0;
  width: 400px;
  text-align: center;
}
.user-password {
  border-radius: 8px 0 0 8px !important;
  width: 347px !important;
  margin: auto;
  padding: 0.67rem 1rem;
  border: 1px solid #004aad;
}
.toggle-password {
  padding: 0.67rem;
  background-color: #004aad;
  border-radius: 0 8px 8px 0;
  color: #ffde59;
  border: 1px solid #004aad;
  border-left: none;
  flex: 1;
}
.toggle-password:hover {
  background-color: #ffde59;
  color: #004aad;
}
.password-div {
  display: flex;
  flex-direction: column;
}
.current-password-div {
  margin: 25px 0 10px 0;
}
.input-and-button {
  display: flex;
  flex-direction: row;
}
.submit-button {
  position: relative;
  margin: 10px auto;
  display: block;
  font-weight: 500;
  padding: 0.67rem 1rem;
  background-color: #004aad;
  border-radius: 8px;
  color: #ffde59;
  border: 1px solid #004aad;
  min-width: max-content;
}
.submit-button:hover {
  background-color: #ffde59;
  color: #004aad;
}
.submit-button[disabled] {
  background-color: #a9a9a9;
  color: #004aad;
}
.submit-button[disabled]:hover {
  cursor: not-allowed;
}
.submit-button[disabled]:after {
  top: 50.25px;
  left: 50%;
  transform: translateX(-50%);
  content: attr(data-text);
  position: absolute;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  opacity: 0;
  background-color: #004aad;
  z-index: 2;
  pointer-events: none;
  color: #ffde59;
  font-weight: 400;
}
.submit-button[disabled]:hover::after {
  opacity: 1;
}
</style>
