<template>
  <div class="content-section account-details">
    <h1 class="section-title">
      <i class="fa-solid fa-mailbox-flag-up"></i>
      Message Center
    </h1>
    <p>Message center content will go here.</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.content-section {
  padding: 0 0 0 40px;
  max-width: max-content;
}
.section-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 10px 0;
  color: #004aad;
}
</style>
