<template>
  <div class="not-found-page flex flex-col">
    <div class="header-container shadow mb-8">
      <Header />
    </div>
    <img
      src="@/assets/images/biglo_logo.png"
      class="w-48 mx-auto"
      alt="Biglo's Logo"
    />
    <h1
      class="title text-3xl font-bold text-darkblue max-w-fit mx-auto my-6 flex flex-col space-y-2"
    >
      <span>The page you’re looking for</span>
      <span class="block mx-auto">can’t be found.</span>
    </h1>
    <router-link
      to="/"
      class="mx-auto my-auto border border-darkblue text-yellow text-xl bg-darkblue py-2 px-2 font-medium rounded-md shadow mt-2 mb-8 hover:bg-yellow hover:text-darkblue"
    >
      <i class="fa-solid fa-house-chimney mr-2 ml-1"> </i>Want to return home?
    </router-link>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
export default {
  name: "NotFoundPage",
  metaInfo: {
    title: "404: Page not found | Biglo",
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped></style>
