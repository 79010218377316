<template>
  <footer
    class="footer container max-w-[90vw] w-full mx-auto bg-darkblue text-white py-5 mt-5"
  >
    <div class="footer-content flex justify-around md:flex-col md:items-center">
      <!-- About Section -->
      <div class="footer-section mb-3 about text-center">
        <h3
          :class="{ clickable: isMobile, active: ulVisibility['ul-biglo'] }"
          @click="isMobile && handleClick('ul-biglo')"
          class="text-yellow text-xl font-medium md:cursor-pointer md:border md:border-white md:px-2 md:py-1.5 md:rounded md:mx-auto md:w-min md:hover:border-yellow"
        >
          Biglo
        </h3>
        <ul
          v-show="ulVisibility['ul-biglo']"
          class="space-y-1.5 mt-2"
          id="ul-biglo"
        >
          <li class="md:mb-2 md:mt-3">
            <a href="/about-us" class="text-white hover:text-yellow"
              >About us</a
            >
          </li>
          <li class="md:mb-2">
            <a href="/our-team" class="text-white hover:text-yellow"
              >Our Team</a
            >
          </li>
          <li class="md:mb-2">
            <a href="/newsroom" class="text-white hover:text-yellow"
              >Newsroom/Blog</a
            >
          </li>
          <li>
            <a href="/investor&partners" class="text-white hover:text-yellow"
              >Investors/Partners</a
            >
          </li>
        </ul>
      </div>

      <!-- Resources Section -->
      <div class="footer-section mb-3 links text-center">
        <h3
          :class="{ clickable: isMobile, active: ulVisibility['ul-resources'] }"
          @click="isMobile && handleClick('ul-resources')"
          class="text-yellow text-xl font-medium md:cursor-pointer md:border md:border-white md:px-2 md:py-1.5 md:rounded md:mx-auto md:w-min md:hover:border-yellow"
        >
          Resources
        </h3>
        <ul
          v-show="ulVisibility['ul-resources']"
          class="space-y-1.5 mt-2"
          id="ul-resources"
        >
          <li><a href="/faq" class="text-white hover:text-yellow">FAQ</a></li>
          <li>
            <a href="/privacy-policy" class="text-white hover:text-yellow"
              >Privacy Policy</a
            >
          </li>
          <li>
            <a href="/terms-of-services" class="text-white hover:text-yellow"
              >Terms of Services</a
            >
          </li>
          <li>
            <a href="/why-us" class="text-white hover:text-yellow"
              >Why Renting your Space?</a
            >
          </li>
        </ul>
      </div>

      <!-- Social Section -->
      <div class="footer-section mb-3 social text-center">
        <h3
          :class="{ clickable: isMobile, active: ulVisibility['ul-social'] }"
          @click="isMobile && handleClick('ul-social')"
          class="text-yellow text-xl font-medium md:cursor-pointer md:border md:border-white md:px-2 md:py-1.5 md:rounded md:mx-auto md:w-fit md:hover:border-yellow"
        >
          Follow Us
        </h3>
        <ul v-show="ulVisibility['ul-social']" class="mt-2" id="ul-social">
          <li>
            <a
              href="https://www.linkedin.com/company/biglointernational/"
              target="_blank"
              class="flex w-5 mx-auto"
            >
              <i
                class="fa-brands fa-linkedin hover:text-yellow fa-lg mt-3.5 mb-4"
              ></i>
            </a>
          </li>
        </ul>
      </div>

      <!-- Contact Section -->
      <div class="footer-section mb-3 contact text-center">
        <h3
          :class="{ clickable: isMobile, active: ulVisibility['ul-contact'] }"
          @click="isMobile && handleClick('ul-contact')"
          class="text-yellow text-xl font-medium md:cursor-pointer md:border md:border-white md:px-2 md:py-1.5 md:rounded md:mx-auto md:w-fit md:hover:border-yellow"
        >
          Contact Us
        </h3>
        <ul
          class="space-y-1.5"
          v-show="ulVisibility['ul-contact']"
          id="ul-contact"
        >
          <li class="flex items-center mb-2 mt-2 ml-2 hover:text-yellow">
            <i class="fa-solid fa-phone mr-4" aria-hidden="true"></i>
            <a href="tel: +34 633105371" target="_blank">+34 633105371</a>
          </li>
          <li class="flex items-center mt-2 ml-2 hover:text-yellow">
            <i class="fa-solid fa-envelope mr-4" aria-hidden="true"></i>
            <a href="mailto:info@biglo.space" target="_blank"
              >info@biglo.space</a
            >
          </li>
        </ul>
      </div>
    </div>

    <!-- Footer Bottom Section -->
    <div class="footer-bottom mt-5 text-center">
      <p>&copy; 2024 Biglo. All rights reserved.</p>
    </div>
  </footer>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "CommonFooter",
  data() {
    return {
      isMobile: false,
      ulVisibility: {
        "ul-biglo": false,
        "ul-resources": false,
        "ul-social": false,
        "ul-contact": false,
      },
    };
  },
  setup() {
    const isHovered = ref(false);

    return { isHovered };
  },
  mounted() {
    this.isMobile = window.innerWidth <= 768;
    window.addEventListener("resize", this.handleResize);

    if (!this.isMobile) {
      this.ulVisibility = {
        "ul-biglo": true,
        "ul-resources": true,
        "ul-social": true,
        "ul-contact": true,
      };
    }
  },
  beforeUnmount() {
    // Cleanup tasks before the component is unmounted
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
      if (this.isMobile) {
        // Close all ULs on resize if in mobile view
        for (let key in this.ulVisibility) {
          this.ulVisibility[key] = false;
        }
      }
      if (!this.isMobile) {
        // If switching to desktop view, ensure all ULs are visible
        for (let key in this.ulVisibility) {
          this.ulVisibility[key] = true;
        }
      } else {
        // If switching to mobile view, close all ULs
        for (let key in this.ulVisibility) {
          this.ulVisibility[key] = false;
        }
      }
    },
    handleClick(id) {
      // Close all ULs first
      for (let key in this.ulVisibility) {
        if (key !== id) {
          this.ulVisibility[key] = false;
        }
      }
      // Toggle the clicked UL
      this.ulVisibility[id] = !this.ulVisibility[id];
    },
  },
});
</script>

<style scoped>
.active {
  border-color: #ffde59;
}
footer {
  transition: all 0.5s ease-out;
}
</style>
