<template>
  <div class="header-container shadow">
    <Header />
  </div>
  <div class="wrapper">
    <YourAccount />
  </div>
</template>
<script>
import Header from "@/components/common/Header.vue";
import YourAccount from "@/components/Account/YourAccount.vue";

export default {
  name: "YourAccountView",
  components: {
    Header,
    YourAccount,
  },
};
</script>
<style scoped>
.wrapper {
  max-width: 90vw;
  margin: 16px auto;
}
</style>
