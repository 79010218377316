<template>
  <div class="content-section account-details">
    <h1 class="section-title">
      <i class="fa-solid fa-user-tie fa-lg"></i>
      Corporate Details
    </h1>
    <form @submit.prevent="updateCorporateDetails">
      <div class="input-container">
        <label for="corporate-name">Name</label>
        <input
          type="text"
          name="corporate-name"
          v-model="formData.name"
          id="corporate-name"
          placeholder="Name"
        />
      </div>
      <div class="input-container">
        <label for="corporate-website">Website</label>
        <input
          type="url"
          name="corporate-website"
          v-model="formData.website"
          id="corporate-website"
          placeholder="Website"
        />
      </div>
      <div class="input-container">
        <label for="corporate-sector">Sector</label>
        <input
          type="text"
          name="corporate-sector"
          v-model="formData.sector"
          id="corporate-sector"
          placeholder="Sector"
        />
      </div>
      <div class="input-container">
        <label for="corporate-country">Country</label>
        <input
          type="text"
          name="corporate-country"
          v-model="formData.country"
          id="corporate-country"
          placeholder="Country"
        />
      </div>
      <div class="input-container">
        <label for="corporate-nif">NIF</label>
        <input
          type="text"
          name="corporate-nif"
          v-model="formData.nif"
          id="corporate-nif"
          placeholder="NIF"
        />
      </div>
      <span v-if="!isFormValid()" class="error-message">
        Please fill in all required fields correctly.
      </span>
      <button
        type="submit"
        class="submit-button"
        :disabled="isFormInvalid()"
        :data-text="dataText"
      >
        Save Changes
      </button>
    </form>
    <!-- Notification Component -->
    <NotificationCustom
      v-for="(notification, index) in notifications"
      :key="index"
      :type="notification.type"
      :message="notification.message"
      :duration="notification.duration"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import NotificationCustom from "@/components/common/NotificationCustom.vue";

export default {
  name: "CorporateDetails",
  components: { NotificationCustom },
  data() {
    return {
      originalData: {
        name: "",
        website: "",
        sector: "",
        country: "",
        nif: "",
      },
      formData: {
        name: "",
        website: "",
        sector: "",
        country: "",
        nif: "",
      },
      notifications: [],
    };
  },
  computed: {
    ...mapState({
      corporate: (state) => state.corporate,
    }),
    dataText() {
      if (this.noChangesMade()) {
        return "You haven't made any change";
      } else if (this.isFieldEmpty()) {
        return "You cannot leave formerly filled fields empty";
      } else {
        return "Check every field";
      }
    },
  },
  watch: {
    corporate: {
      immediate: true,
      handler(newCorporate) {
        if (newCorporate) {
          this.setCorporateData(newCorporate);
        }
      },
    },
  },
  methods: {
    ...mapActions(["fetchCorporateDetails", "updateCorporateDetailsAction"]),
    setCorporateData(corporateData) {
      this.originalData = { ...corporateData };
      this.formData = { ...this.originalData };
    },
    async updateCorporateDetails() {
      if (this.isFormInvalid()) return;
      try {
        const updatedCorporate = { ...this.formData };
        await this.updateCorporateDetailsAction({
          corporate: updatedCorporate,
        });
        this.addNotification(
          "Corporate details updated successfully!",
          "success"
        );
        this.setCorporateData(updatedCorporate);
      } catch (error) {
        console.error("Error updating corporate details:", error);
        this.addNotification("Error submitting form.", "error");
      }
    },
    isFormValid() {
      return (
        this.formData.name &&
        this.formData.website &&
        this.formData.sector &&
        this.formData.country &&
        this.formData.nif
      );
    },
    isFormInvalid() {
      return this.noChangesMade() || this.isFieldEmpty();
    },
    noChangesMade() {
      return (
        this.formData.name === this.originalData.name &&
        this.formData.website === this.originalData.website &&
        this.formData.sector === this.originalData.sector &&
        this.formData.country === this.originalData.country &&
        this.formData.nif === this.originalData.nif
      );
    },
    isFieldEmpty() {
      return (
        (this.originalData.name && !this.formData.name) ||
        (this.originalData.website && !this.formData.website) ||
        (this.originalData.sector && !this.formData.sector) ||
        (this.originalData.country && !this.formData.country) ||
        (this.originalData.nif && !this.formData.nif)
      );
    },
    addNotification(message, type, duration = 10000) {
      this.notifications.push({ message, type, duration });
      setTimeout(() => {
        this.notifications.shift();
      }, duration);
    },
  },
  async mounted() {
    try {
      const corporateData = await this.fetchCorporateDetails();
      this.setCorporateData(corporateData);
    } catch (error) {
      console.error("Error loading corporate details:", error);
      this.addNotification("Error loading corporate details.", "error");
    }
  },
};
</script>

<style scoped>
.content-section {
  padding: 0 0 0 40px;
  max-width: max-content;
}
.section-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 10px 0;
  color: #004aad;
}
.input-container {
  margin: 10px 0;
}
.input-container > label {
  display: block;
}
input[type="text"],
input[type="url"] {
  border-radius: 8px;
  width: 400px;
  margin: auto;
  padding: 0.67rem 1rem;
  border: 1px solid #004aad;
}
.submit-button {
  position: relative;
  margin: 10px auto;
  display: block;
  font-weight: 500;
  padding: 0.67rem 1rem;
  background-color: #004aad;
  border-radius: 8px;
  color: #ffde59;
  border: 1px solid #004aad;
  min-width: max-content;
}
.submit-button:hover {
  background-color: #ffde59;
  color: #004aad;
}
.submit-button[disabled] {
  background-color: #a9a9a9;
  color: #004aad;
}
.submit-button[disabled]:hover {
  cursor: not-allowed;
}
.submit-button[disabled]:after {
  top: 50.25px;
  left: 50%;
  transform: translateX(-50%);
  content: attr(data-text);
  position: absolute;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  opacity: 0;
  background-color: #004aad;
  z-index: 2;
  pointer-events: none;
  color: #ffde59;
  font-weight: 400;
}
.submit-button[disabled]:hover::after {
  opacity: 1;
}
</style>
