<template>
  <div class="home">
    <Header />
    <MainWebflow />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import MainWebflow from "@/components/Home/MainWebflow.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  name: "HomePage",
  components: {
    Header,
    MainWebflow,
    Footer,
  },
};
</script>

<style></style>
