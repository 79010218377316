<template>
  <div class="header-container shadow">
    <Header />
  </div>
  <div class="wrapper">
    <SpaceDetails />
  </div>
  <Footer />
</template>
<script>
import Header from "@/components/common/Header.vue";
import SpaceDetails from "@/components/Spaces/SpaceDetails.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  name: "SpaceDetailsView",
  components: {
    Header,
    SpaceDetails,
    Footer,
  },
};
</script>
<style scoped>
.wrapper {
  max-width: 90vw;
  margin: 16px auto;
}
</style>
