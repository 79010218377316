<template>
  <div class="aside-section">
    <!-- h2 Greeting -->
    <h2>
      Welcome back,
      <span class="username">{{ username }}</span>
      !
    </h2>
    <!-- Greeting Separator -->
    <div class="separator-greeting"></div>
    <!-- Nav Buttons -->
    <ul class="nav-buttons">
      <li v-for="button in buttons" :key="button.section">
        <div
          @click="$emit('section-change', button.section)"
          :class="[
            'nav-button',
            { 'current-button': currentSection === button.section },
          ]"
        >
          <i :class="button.icon"></i>
          {{ button.text }}
        </div>
        <div v-if="button.separator" class="separator-nav-buttons"></div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    currentSection: String,
  },
  data() {
    return {
      buttons: [
        {
          section: "UserDetails",
          text: "User Details",
          icon: "fa-solid fa-user",
          separator: false,
        },
        {
          section: "CorporateDetails",
          text: "Corporate Details",
          icon: "fa-solid fa-user-tie",
          separator: false,
        },
        {
          section: "MessageCenter",
          text: "Message Center",
          icon: "fa-solid fa-mailbox-flag-up",
          separator: true,
        },
        {
          section: "MyFavourites",
          text: "My Favourites",
          icon: "fa-solid fa-heart",
          separator: false,
        },
        {
          section: "SavedSearches",
          text: "Saved Searches",
          icon: "fa-solid fa-user-magnifying-glass",
          separator: true,
        },
        {
          section: "SpacesManagement",
          text: "Spaces Management",
          icon: "fa-solid fa-shop-lock",
          separator: false,
        },
        {
          section: "ServicesManagement",
          text: "Services Management",
          icon: "fa-solid fa-gears",
          separator: false,
        },
        {
          section: "AdsManagement",
          text: "Ads Management",
          icon: "fa-solid fa-ad",
          separator: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    username() {
      return this.user ? this.user.name : "Guest";
    },
  },
};
</script>

<style scoped>
.aside-section {
  max-width: 300px;
  margin-right: 20px;
}
.aside-section h2 {
  font-size: 1.15rem;
  font-weight: 450;
  margin: 25px 0;
}
.username {
  color: #004aad;
}
.separator-greeting {
  background-color: #004aad;
  height: 2.5px;
  width: 100%;
  border-radius: 2.5px;
}
.nav-buttons {
  width: max-content;
  padding-top: 17px;
}
.nav-button {
  color: #004aad;
  font-size: 1.15rem;
  font-weight: 500;
  margin: 8px 0;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
}
.nav-button:hover,
.current-button {
  background-color: #004aad;
  color: #ffde59;
}
.nav-button i {
  margin-right: 4px;
}
.separator-nav-buttons {
  margin: 10px 0;
  border-top: 1px solid black;
  width: 100%;
}
</style>
